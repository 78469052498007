import React from 'react';

const OllaCartAdd = (props) => {
  return (
    <svg {...props} className="custom-logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="829.77px" height="202.28px" viewBox="0 0 829.77 202.28" enableBackground="new 0 0 829.77 202.28">
      <path d="M815.469,202.042H13.791c-7.747,0-14.046-6.301-14.046-14.047V13.812c0-7.745,6.3-14.047,14.046-14.047
        h801.678c7.745,0,14.047,6.302,14.047,14.047v174.184C829.516,195.741,823.214,202.042,815.469,202.042z M13.791,8.918
        c-2.698,0-4.892,2.195-4.892,4.894v174.184c0,2.697,2.194,4.892,4.892,4.892h801.678c2.699,0,4.893-2.194,4.893-4.892V13.812
        c0-2.699-2.193-4.894-4.893-4.894H13.791z"/>
      <g>
        <path d="M63.694,126.809c0-14.438,9.656-23.062,21.094-23.062c7.125,0,11.907,3.094,14.626,7.78h0.187v-6.374h9.188
          v43.312h-9.188v-6.374h-0.187c-2.719,4.688-7.501,7.781-14.626,7.781C73.351,149.871,63.694,141.246,63.694,126.809z
            M100.07,126.809c0-8.25-5.251-14.062-13.313-14.062c-8.061,0-13.312,5.812-13.312,14.062s5.251,14.063,13.312,14.063
          C94.819,140.872,100.07,135.059,100.07,126.809z"/>
        <path d="M117.228,126.809c0-14.438,9.656-23.062,21.094-23.062c7.125,0,11.906,3.094,14.625,7.78h0.188V82.839
          h9.188v65.625h-9.188v-6.374h-0.188c-2.719,4.688-7.5,7.781-14.625,7.781C126.884,149.871,117.228,141.246,117.228,126.809z
            M153.601,126.809c0-8.25-5.249-14.062-13.311-14.062c-8.062,0-13.313,5.812-13.313,14.062s5.251,14.063,13.313,14.063
          C148.352,140.872,153.601,135.059,153.601,126.809z"/>
        <path d="M171.228,126.809c0-14.438,9.655-23.062,21.094-23.062c7.125,0,11.906,3.094,14.624,7.78h0.187V82.839
          h9.189v65.625h-9.189v-6.374h-0.187c-2.718,4.688-7.499,7.781-14.624,7.781C180.883,149.871,171.228,141.246,171.228,126.809z
            M207.603,126.809c0-8.25-5.251-14.062-13.313-14.062c-8.062,0-13.312,5.812-13.312,14.062s5.251,14.063,13.312,14.063
          C202.352,140.872,207.603,135.059,207.603,126.809z"/>
        <path d="M260.853,112.934v22.688c0,3.094,1.311,4.499,4.498,4.499h2.25v8.438h-4.219
          c-7.593,0-11.718-4.031-11.718-11.156v-24.469h-7.689v-7.781h7.689V87.808h9.188v17.344h8.811v7.781H260.853z"/>
        <path d="M321.788,126.809c0,13.312-10.126,23.062-23.719,23.062c-13.595,0-23.719-9.751-23.719-23.062
          s10.124-23.062,23.719-23.062C311.662,103.746,321.788,113.497,321.788,126.809z M284.1,126.809c0,8.531,5.72,14.063,13.97,14.063
          s13.968-5.532,13.968-14.063s-5.718-14.062-13.968-14.062S284.1,118.277,284.1,126.809z"/>
        <path d="M510.786,126.809c0,13.312-10.125,23.062-23.718,23.062c-13.595,0-23.721-9.751-23.721-23.062
          s10.126-23.062,23.721-23.062C500.661,103.746,510.786,113.497,510.786,126.809z M473.099,126.809
          c0,8.531,5.718,14.063,13.97,14.063c8.249,0,13.969-5.532,13.969-14.063s-5.72-14.062-13.969-14.062
          C478.816,112.746,473.099,118.277,473.099,126.809z"/>
        <path d="M528.88,148.464h-9.188V82.839h9.188V148.464z"/>
        <path d="M550.256,148.464h-9.188V82.839h9.188V148.464z"/>
        <path d="M559.161,126.809c0-14.438,9.657-23.062,21.095-23.062c7.124,0,11.906,3.094,14.625,7.78h0.188v-6.374
          h9.188v43.312h-9.188v-6.374h-0.188c-2.719,4.688-7.501,7.781-14.625,7.781C568.818,149.871,559.161,141.246,559.161,126.809z
            M595.537,126.809c0-8.25-5.251-14.062-13.313-14.062s-13.313,5.812-13.313,14.062s5.251,14.063,13.313,14.063
          S595.537,135.059,595.537,126.809z"/>
        <path d="M615.037,126.809c0-13.782,9.844-23.062,23.812-23.062c5.624,0,10.406,1.499,14.812,4.405l-4.312,7.781
          c-3-1.874-6.75-3.28-10.781-3.28c-8.344,0-13.782,5.999-13.782,14.156c0,8.156,5.438,14.156,13.782,14.156
          c4.031,0,7.781-1.406,10.781-3.28l4.312,7.78c-4.406,2.906-9.188,4.406-14.812,4.406
          C624.881,149.871,615.037,140.591,615.037,126.809z"/>
        <path d="M658.35,126.809c0-14.438,9.655-23.062,21.095-23.062c7.124,0,11.906,3.094,14.625,7.78h0.187v-6.374h9.188
          v43.312h-9.188v-6.374h-0.187c-2.719,4.688-7.501,7.781-14.625,7.781C668.005,149.871,658.35,141.246,658.35,126.809z
            M694.725,126.809c0-8.25-5.25-14.062-13.314-14.062c-8.061,0-13.311,5.812-13.311,14.062s5.25,14.063,13.311,14.063
          C689.475,140.872,694.725,135.059,694.725,126.809z"/>
        <path d="M739.069,114.62h-1.407c-9,0-13.78,4.313-13.78,14.626v19.218h-9.188v-43.312h9.188v9.468h0.188
          c2.062-6.469,7.03-10.781,14.344-10.781h0.656V114.62z"/>
        <path d="M758.287,112.934v22.688c0,3.094,1.312,4.499,4.499,4.499h2.251v8.438h-4.22
          c-7.593,0-11.719-4.031-11.719-11.156v-24.469h-7.687v-7.781h7.687V87.808h9.188v17.344h8.812v7.781H758.287z"/>
      </g>
      <path fill="#009FB7" d="M400.645,130.529c-1.441,6.359-6.586,10.309-13.551,10.309c-8.25,0-13.969-5.532-13.969-14.066
        c0-4.894,1.889-8.793,5.051-11.235l-3.271-7.9l1.15,2.778l-1.458-3.514c-6.854,3.916-11.223,11.125-11.223,19.871
        c0,13.316,10.125,23.067,23.72,23.067c12.282,0,21.73-7.956,23.442-19.31h-1.243H400.645z"/>
      <path fill="#FED766" d="M494.841,96.478c-0.66,0-1.331-0.143-1.966-0.446c-2.281-1.087-3.25-3.819-2.164-6.101
        c3.33-6.99,6.134-14.633,6.777-18.234c-7.413-0.881-31.163-0.334-52.369,0.83c-2.516,0.136-4.684-1.797-4.822-4.32
        c-0.136-2.524,1.797-4.682,4.32-4.822c0.131-0.007,13.18-0.719,26.631-1.071c28.437-0.747,30.879,0.749,32.495,1.737
        c4.628,2.836,3.377,9.38,0.072,18.334c-2.151,5.824-4.731,11.257-4.841,11.485C498.19,95.515,496.551,96.478,494.841,96.478z"/>
      <path fill="#FE4A49" d="M449.947,121.367h-57.301c-1.19,0-2.431-0.643-2.756-1.427c-0.004-0.005-0.007-0.011-0.009-0.014
        l-26.312-63.372h-0.002l-2.004-4.821c-0.664-1.602-2.629-2.914-4.365-2.914h-2.492h-3.887h-25.75c-2.525,0-4.575,2.049-4.575,4.577
        c0,2.527,2.05,4.576,4.575,4.576h25.75h0.269c1.729,0,3.682,1.302,4.354,2.896l25.374,61.08c0.008,0.021,0.013,0.042,0.021,0.062
        l2.08,5.011c0.799,1.923,3.159,3.498,5.242,3.498h2.863c0.009,0,0.017-0.002,0.024-0.002l58.899,0.002
        c2.526,0,4.576-2.049,4.576-4.577C454.523,123.416,452.474,121.367,449.947,121.367z"/>
    </svg>
  )
}

export default OllaCartAdd;