import React from 'react';
import Layout from '../layout';

const Support = () => {  

  return (
    <Layout>
      <iframe title='Support' className='privacy-iframe' src="/support.html"></iframe>
    </Layout>
  )
}

export default Support;
