export const Constants = {
  SIGNIN: 'SIGN_IN',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_FAILED: 'SIGNIN_FAILED',
  SIGNOUT: 'SIGN_OUT',
  VERIFY: 'VERIFY_USER',
  SETERROR: 'SET_ERROR',
  SETLOADING: 'SET_LOADING',
  SETVERIFYLOADING: 'SET_VERIFY_LOADING',
  VERIFYEMAIL: 'SIGNIN_VERIFY',
};