import React from 'react';

const BagLogo = (props) => {
  return (
    <svg {...props} className="custom-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
      <path d="M137.951,142.258c-7.809,0-13.35-2.621-18.496-5.056c-6.731-3.184-12.545-5.934-23.656-3.192
        c-2.361,0.581-4.75-0.861-5.334-3.225s0.859-4.752,3.225-5.334c14.155-3.492,22.324,0.373,29.533,3.782
        c8.285,3.918,15.438,7.303,32.91-0.453c2.229-0.988,4.83,0.015,5.818,2.24c0.987,2.225-0.016,4.83-2.241,5.816
        C150.721,140.827,143.717,142.258,137.951,142.258z"/>
      <path d="M137.951,167.398c-7.809,0-13.35-2.621-18.496-5.057c-6.731-3.182-12.545-5.932-23.656-3.191
        c-2.361,0.582-4.75-0.86-5.334-3.225c-0.584-2.363,0.859-4.752,3.225-5.335c14.155-3.491,22.324,0.373,29.533,3.782
        c8.285,3.918,15.438,7.303,32.91-0.453c2.229-0.988,4.83,0.016,5.818,2.24c0.987,2.225-0.016,4.83-2.241,5.816
        C150.721,165.969,143.717,167.398,137.951,167.398z"/>
      <path d="M127.361,204.039c-2.402,0-4.369-1.929-4.404-4.34c-0.038-2.434,1.903-4.438,4.339-4.475
        c16.747-0.258,32.62-4.137,41.425-10.123c14.956-10.167,15.051-28.762,15.148-48.449c0.033-6.611,0.068-13.449,0.68-20.063
        c0.224-2.424,2.377-4.208,4.794-3.984c2.425,0.224,4.208,2.37,3.984,4.795c-0.577,6.232-0.61,12.873-0.644,19.297
        c-0.105,21.102-0.214,42.92-19.006,55.695c-10.152,6.9-27.872,11.363-46.246,11.646
        C127.408,204.039,127.385,204.039,127.361,204.039z"/>
      <path d="M127.364,204.039c-0.022,0-0.046,0-0.068,0c-18.376-0.283-36.095-4.746-46.246-11.646
        c-18.794-12.775-18.902-34.596-19.009-55.697c-0.032-6.422-0.065-13.063-0.641-19.295c-0.225-2.425,1.561-4.571,3.984-4.795
        c2.411-0.225,4.569,1.56,4.794,3.984c0.61,6.614,0.646,13.45,0.679,20.062c0.099,19.688,0.191,38.283,15.148,48.45
        c8.805,5.986,24.678,9.865,41.426,10.123c2.434,0.037,4.377,2.041,4.34,4.475C131.734,202.11,129.768,204.039,127.364,204.039z"/>
      <path d="M151.168,106.15c-2.436,0-4.41-1.974-4.41-4.408c0-17.191-3.689-27.007-6.785-32.213
        c-3.432-5.772-7.941-8.951-12.697-8.951c-10.583,0-21.527,15.397-21.527,41.164c0,2.434-1.974,4.408-4.408,4.408
        s-4.408-1.974-4.408-4.408c0-32.812,15.266-49.979,30.344-49.979c7.989,0,15.189,4.709,20.273,13.261
        c5.252,8.829,8.026,21.526,8.026,36.719C155.575,104.176,153.602,106.15,151.168,106.15z"/>
    </svg>
  )
}

export default BagLogo;