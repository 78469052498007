import React from 'react';

const UserLogo = (props) => {
  return (
    <svg {...props} className="custom-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
      <path d="M143.545,198.84h-28.423c-3.073,0-5.564-2.491-5.564-5.563s2.491-5.563,5.564-5.563h28.423
        c3.071,0,5.562,2.491,5.562,5.563S146.616,198.84,143.545,198.84z"/>
      <path d="M156.408,86.437c0,16.185-12.311,28.037-28.835,28.037s-28.835-11.852-28.835-28.037
        c0-16.182,12.311-28.036,28.835-28.036S156.408,70.254,156.408,86.437z M110.591,86.437c0,10.373,6.952,17.098,16.982,17.098
        s16.979-6.725,16.979-17.098c0-10.37-6.949-17.095-16.979-17.095S110.591,76.066,110.591,86.437z"/>
      <path d="M168.452,188.481c-1.938,0-3.822-1.015-4.846-2.822c-1.514-2.674-0.575-6.069,2.1-7.583
        c6.584-3.729,14.131-10.061,15.572-16.65c2.321-10.618,0.113-18.993-6.95-26.354c-5.646-5.883-9.553-8.367-18.43-12.841
        c-2.743-1.384-3.848-4.729-2.463-7.473c1.381-2.744,4.728-3.846,7.472-2.463c9.542,4.81,14.632,7.968,21.45,15.073
        c9.596,10,12.89,22.258,9.792,36.434c-2.724,12.449-15.564,20.9-20.959,23.955C170.323,188.248,169.382,188.481,168.452,188.481z"/>
      <path d="M86.694,188.481c-0.931,0-1.871-0.233-2.738-0.724c-5.396-3.055-18.236-11.506-20.959-23.955
        c-3.099-14.176,0.196-26.434,9.792-36.434c6.817-7.105,11.907-10.263,21.45-15.073c2.745-1.383,6.09-0.28,7.471,2.463
        c1.385,2.744,0.281,6.088-2.462,7.473c-8.877,4.474-12.785,6.958-18.431,12.841c-7.062,7.36-9.271,15.735-6.951,26.354
        c1.44,6.589,8.989,12.921,15.574,16.649c2.673,1.514,3.612,4.909,2.1,7.583C90.514,187.467,88.632,188.481,86.694,188.481z"/>
    </svg>
  )
}

export default UserLogo;