import React from 'react';

const CartLogo = (props) => {
  return (
    <svg {...props} className="custom-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
      <path d="M229.474,157.608c0,14.243-10.832,24.672-25.375,24.672c-14.541,0-25.372-10.429-25.372-24.672
        c0-14.239,10.831-24.671,25.372-24.671C218.642,132.938,229.474,143.369,229.474,157.608z M189.155,157.608
        c0,9.127,6.119,15.045,14.943,15.045c8.825,0,14.943-5.918,14.943-15.045c0-9.125-6.118-15.042-14.943-15.042
        C195.274,142.566,189.155,148.483,189.155,157.608z"/>
      <path d="M111.704,161.59c-1.542,6.8-7.044,11.025-14.492,11.025c-8.825,0-14.943-5.918-14.943-15.045
        c0-5.235,2.021-9.406,5.403-12.02l-3.501-8.449l1.231,2.971l-1.558-3.758c-7.331,4.189-12.008,11.9-12.008,21.256
        c0,14.242,10.832,24.672,25.375,24.672c13.136,0,23.24-8.511,25.071-20.652h-1.33H111.704z"/>
      <path d="M212.455,125.167c-0.705,0-1.423-0.153-2.102-0.477c-2.44-1.163-3.476-4.083-2.314-6.525
        c3.562-7.477,6.56-15.651,7.246-19.502c-7.927-0.943-33.33-0.357-56.013,0.886c-2.691,0.147-5.008-1.92-5.156-4.62
        c-0.147-2.7,1.922-5.008,4.621-5.156c0.139-0.008,14.096-0.77,28.484-1.147c30.414-0.8,33.027,0.801,34.756,1.86
        c4.949,3.033,3.611,10.032,0.077,19.608c-2.298,6.229-5.061,12.04-5.177,12.284C216.038,124.138,214.284,125.167,212.455,125.167z"
        />
      <path d="M164.437,151.789h-61.287c-1.274,0-2.602-0.688-2.95-1.525c-0.001-0.006-0.005-0.011-0.008-0.016
        L72.049,82.466l-0.004,0.002l-2.142-5.156c-0.711-1.716-2.812-3.12-4.671-3.12H62.57h-4.159H30.87c-2.703,0-4.895,2.192-4.895,4.896
        c0,2.704,2.191,4.895,4.895,4.895h27.541h0.287c1.848,0,3.939,1.392,4.66,3.098l27.137,65.331c0.009,0.022,0.015,0.043,0.023,0.066
        l2.227,5.359c0.853,2.059,3.376,3.741,5.605,3.741h3.063c0.009,0,0.018-0.001,0.025-0.001l62.997,0.001
        c2.703,0,4.896-2.191,4.896-4.896C169.332,153.98,167.14,151.789,164.437,151.789z"/>
    </svg>
  )
}

export default CartLogo;