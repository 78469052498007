import React from 'react';

const UserLogo = (props) => {
  return (
    <svg {...props} className="custom-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" width="256"
      height="256" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
      <path
        d="M143.545,207.10446294784546 h-28.423000000000002 c-3.073,0 -5.564,-2.491 -5.564,-5.563 s2.491,-5.563 5.564,-5.563 h28.423000000000002 c3.071,0 5.562,2.491 5.562,5.563 S146.616,207.10446294784546 143.545,207.10446294784546 z"
        id="svg_1" />
      <path
        d="M156.408,94.70146294784546 c0,16.185 -12.311,28.037 -28.835,28.037 s-28.835,-11.852 -28.835,-28.037 c0,-16.182 12.311,-28.036 28.835,-28.036 S156.408,78.51846294784546 156.408,94.70146294784546 zM110.591,94.70146294784546 c0,10.373 6.952,17.098 16.982,17.098 s16.979,-6.725 16.979,-17.098 c0,-10.37 -6.949,-17.095 -16.979,-17.095 S110.591,84.33046294784546 110.591,94.70146294784546 z"
        id="svg_2" />
      <path
        d="M99.71378529834746,83.46179672431947 c0,16.185 -12.311,28.037 -28.835,28.037 s-28.835,-11.852 -28.835,-28.037 c0,-16.182 12.311,-28.036 28.835,-28.036 S99.71378529834746,67.27879672431948 99.71378529834746,83.46179672431947 zM53.89678529834748,83.46179672431947 c0,10.373 6.952,17.098 16.982,17.098 s16.979,-6.725 16.979,-17.098 c0,-10.37 -6.949,-17.095 -16.979,-17.095 S53.89678529834748,73.09079672431946 53.89678529834748,83.46179672431947 z"
        id="svg_7" />
      <path
        d="M32.31383585357666,182.0347216067314 c-0.931,0 -1.871,-0.233 -2.738,-0.7240000000000006 c-5.396,-3.055 -18.236,-11.506 -20.959,-23.955 c-3.099,-14.176 0.19600000000000214,-26.434 9.792,-36.434 c6.817,-7.105 11.907,-10.263 21.45,-15.073 c2.745,-1.383 6.09,-0.28 7.471,2.463 c1.385,2.744 0.281,6.088 -2.462,7.473 c-8.877,4.474 -12.785,6.958 -18.431,12.841 c-7.062,7.36 -9.271,15.735 -6.9510000000000005,26.354 c1.44,6.589 8.989,12.921 15.574,16.649 c2.673,1.514 3.612,4.909 2.1,7.583 C36.133835853576656,181.02072160673143 34.251835853576665,182.0347216067314 32.31383585357666,182.0347216067314 z"
        id="svg_8" />
      <path
        d="M168.452,196.74546294784545 c-1.9380000000000002,0 -3.822,-1.015 -4.846,-2.822 c-1.514,-2.6740000000000004 -0.5750000000000006,-6.069 2.1,-7.583 c6.584,-3.729 14.131,-10.061 15.572,-16.65 c2.321,-10.618 0.11300000000000066,-18.993 -6.95,-26.354 c-5.646,-5.883 -9.553,-8.367 -18.43,-12.841 c-2.7430000000000003,-1.3840000000000001 -3.848,-4.729 -2.463,-7.473 c1.381,-2.744 4.728,-3.846 7.472,-2.463 c9.542,4.8100000000000005 14.632,7.968 21.45,15.073 c9.596,10 12.89,22.258 9.792,36.434 c-2.724,12.449 -15.564,20.9 -20.959,23.955 C170.323,196.51246294784545 169.382,196.74546294784545 168.452,196.74546294784545 z"
        id="svg_3" />
      <path
        d="M224.1544791240692,182.20000987625122 c-1.9380000000000002,0 -3.822,-1.015 -4.846,-2.822 c-1.514,-2.6740000000000004 -0.5750000000000006,-6.069 2.1,-7.583 c6.584,-3.729 14.131,-10.061 15.572,-16.65 c2.321,-10.618 0.11300000000000072,-18.993 -6.95,-26.354 c-5.646,-5.883 -9.553,-8.367 -18.43,-12.841 c-2.7430000000000003,-1.3840000000000001 -3.848,-4.729 -2.463,-7.473 c1.381,-2.744 4.728,-3.846 7.472,-2.463 c9.542,4.8100000000000005 14.632,7.968 21.45,15.073 c9.596,10 12.89,22.258 9.792,36.434 c-2.724,12.449 -15.564,20.9 -20.959,23.955 C226.02547912406922,181.9670098762512 225.08447912406922,182.20000987625122 224.1544791240692,182.20000987625122 z"
        id="svg_5" />
      <path
        d="M212.77163559961318,83.2965055937767 c0,16.185 -12.311,28.037 -28.835,28.037 s-28.835,-11.852 -28.835,-28.037 c0,-16.182 12.311,-28.036 28.835,-28.036 S212.77163559961318,67.11350559377671 212.77163559961318,83.2965055937767 zM166.9546355996132,83.2965055937767 c0,10.373 6.952,17.098 16.982,17.098 s16.979,-6.725 16.979,-17.098 c0,-10.37 -6.949,-17.095 -16.979,-17.095 S166.9546355996132,72.9255055937767 166.9546355996132,83.2965055937767 z"
        id="svg_6" />
      <path
        d="M86.694,196.74546294784545 c-0.931,0 -1.871,-0.233 -2.738,-0.7240000000000006 c-5.396,-3.055 -18.236,-11.506 -20.959,-23.955 c-3.099,-14.176 0.19600000000000142,-26.434 9.792,-36.434 c6.817,-7.105 11.907,-10.263 21.45,-15.073 c2.745,-1.383 6.09,-0.28 7.471,2.463 c1.385,2.744 0.281,6.088 -2.462,7.473 c-8.877,4.474 -12.785,6.958 -18.431,12.841 c-7.062,7.36 -9.271,15.735 -6.9510000000000005,26.354 c1.44,6.589 8.989,12.921 15.574,16.649 c2.673,1.514 3.612,4.909 2.1,7.583 C90.514,195.73146294784547 88.632,196.74546294784545 86.694,196.74546294784545 z"
        id="svg_4" />
    </svg>
  )
}

export default UserLogo;