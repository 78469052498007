import React from "react";

const OllaCartMultiLogo = (props) => {
  return (
    <svg
      className="custom-logo"
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="363.271px"
      viewBox="0 0 363.271 160.092"
      enableBackground="new 0 0 363.271 160.092"
      {...props}
    >
      <path
        d="M157.296,100.502c0,8.561-6.511,14.83-15.252,14.83c-8.739,0-15.249-6.27-15.249-14.83
        c0-8.559,6.51-14.828,15.249-14.828C150.786,85.674,157.296,91.944,157.296,100.502z M133.062,100.502
        c0,5.486,3.678,9.043,8.981,9.043c5.306,0,8.982-3.557,8.982-9.043c0-5.484-3.676-9.041-8.982-9.041
        C136.74,91.461,133.062,95.018,133.062,100.502z"
      />
      <path d="M168.929,114.428h-5.908V72.232h5.908V114.428z" />
      <path d="M182.671,114.428h-5.907V72.232h5.907V114.428z" />
      <path
        d="M188.398,100.502c0-9.281,6.209-14.828,13.563-14.828c4.581,0,7.655,1.99,9.403,5.004h0.12v-4.1h5.907v27.85
        h-5.907v-4.1h-0.12c-1.748,3.014-4.822,5.004-9.403,5.004C194.607,115.333,188.398,109.788,188.398,100.502z M211.787,100.502
        c0-5.303-3.375-9.041-8.561-9.041c-5.184,0-8.559,3.738-8.559,9.041c0,5.305,3.375,9.043,8.559,9.043
        C208.412,109.545,211.787,105.807,211.787,100.502z"
      />
      <path
        d="M224.325,100.502c0-8.859,6.329-14.828,15.312-14.828c3.615,0,6.689,0.965,9.523,2.834l-2.773,5.002
        c-1.928-1.205-4.34-2.109-6.932-2.109c-5.366,0-8.861,3.857-8.861,9.102s3.495,9.104,8.861,9.104c2.592,0,5.004-0.904,6.932-2.111
        l2.773,5.004c-2.834,1.869-5.908,2.834-9.523,2.834C230.654,115.333,224.325,109.364,224.325,100.502z"
      />
      <path
        d="M252.172,100.502c0-9.281,6.21-14.828,13.563-14.828c4.582,0,7.654,1.99,9.404,5.004h0.119v-4.1h5.906v27.85
        h-5.906v-4.1h-0.119c-1.75,3.014-4.822,5.004-9.404,5.004C258.382,115.333,252.172,109.788,252.172,100.502z M275.562,100.502
        c0-5.303-3.377-9.041-8.561-9.041s-8.561,3.738-8.561,9.041c0,5.305,3.377,9.043,8.561,9.043S275.562,105.807,275.562,100.502z"
      />
      <path
        d="M304.073,92.667h-0.905c-5.785,0-8.861,2.773-8.861,9.404v12.357h-5.905v-27.85h5.905v6.088h0.123
        c1.325-4.158,4.52-6.93,9.222-6.93h0.422V92.667z"
      />
      <path
        d="M316.429,91.583v14.588c0,1.988,0.844,2.893,2.893,2.893h1.447v5.426h-2.713
        c-4.883,0-7.535-2.592-7.535-7.174V91.583h-4.943v-5.004h4.943V75.427h5.908v11.152h5.666v5.004H316.429z"
      />
      <path
        fill="#009FB7"
        d="M86.511,102.895c-0.928,4.088-4.235,6.627-8.71,6.627c-5.305,0-8.982-3.557-8.982-9.043
        c0-3.146,1.215-5.652,3.248-7.223l-2.104-5.078l0.741,1.785l-0.937-2.26c-4.407,2.518-7.217,7.152-7.217,12.775
        c0,8.561,6.51,14.83,15.251,14.83c7.895,0,13.968-5.115,15.069-12.414h-0.799H86.511z"
      />
      <path
        fill="#FED766"
        d="M147.067,81.004c-0.424,0-0.856-0.092-1.263-0.287c-1.467-0.698-2.09-2.454-1.392-3.922
        c2.141-4.494,3.943-9.407,4.356-11.722c-4.765-0.566-20.033-0.214-33.667,0.533c-1.617,0.088-3.01-1.154-3.099-2.777
        s1.155-3.01,2.777-3.099c0.083-0.005,8.473-0.463,17.121-0.689c18.28-0.481,19.851,0.481,20.89,1.117
        c2.975,1.823,2.17,6.03,0.047,11.785c-1.382,3.744-3.042,7.237-3.113,7.384C149.221,80.386,148.167,81.004,147.067,81.004z"
      />
      <path
        fill="#FE4A49"
        d="M118.205,97.004H81.369c-0.766,0-1.563-0.412-1.772-0.916c-0.001-0.004-0.003-0.006-0.004-0.01
        l-16.917-40.74l-0.001,0.001l-1.287-3.1c-0.428-1.031-1.691-1.874-2.808-1.874h-1.601h-2.5H37.926c-1.625,0-2.942,1.317-2.942,2.942
        s1.317,2.942,2.942,2.942h16.553h0.173c1.111,0,2.367,0.836,2.801,1.861l16.31,39.267c0.006,0.014,0.009,0.025,0.014,0.041
        l1.338,3.221c0.513,1.236,2.029,2.248,3.369,2.248h1.841c0.006,0,0.011,0,0.016,0h37.864c1.626,0,2.943-1.318,2.943-2.943
        C121.148,98.323,119.831,97.004,118.205,97.004z"
      />
    </svg>
  );
};

export default OllaCartMultiLogo;
