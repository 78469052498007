import React from 'react';

const ShareLogo = (props) => {
  return (
    <svg {...props} className="custom-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
      <path d="M77.209,128.854c-2.523,0-4.57-2.046-4.57-4.571l-0.002-1.148c-0.01-7.554-0.023-14.69,4.802-22.071
        c11.118-17.01,29.929-21.198,46.812-10.422c0.791,0.505,1.566,1.02,2.332,1.537c2.092,1.413,2.643,4.255,1.229,6.346
        c-1.414,2.093-4.256,2.643-6.348,1.229c-0.699-0.473-1.408-0.943-2.132-1.405c-12.517-7.989-25.954-4.96-34.239,7.717
        c-3.331,5.095-3.322,10.111-3.312,17.055l0.002,1.162C81.782,126.808,79.734,128.854,77.209,128.854z"/>
      <path d="M200.408,76.546c-0.893,0-1.764-0.264-2.504-0.747c-3.559-0.847-20.344-1.725-41.275,0.176
        c-2.521,0.228-4.738-1.625-4.968-4.14c-0.227-2.514,1.626-4.737,4.14-4.966c14.421-1.309,25.777-1.249,31.268-1.095
        c4.007,0.112,7.482,0.339,10.053,0.657c3.386,0.419,6.284,0.988,7.475,3.712c1.01,2.313-0.045,5.009-2.359,6.02
        C201.641,76.422,201.02,76.546,200.408,76.546z"/>
      <path d="M192.051,119.946c-0.438,0-0.883-0.063-1.323-0.197c-2.418-0.729-3.785-3.281-3.054-5.697
        c6.078-20.121,8.597-36.741,8.482-40.396c-0.549-1.386-0.407-3.012,0.517-4.32c1.456-2.062,4.311-2.552,6.37-1.097
        c2.43,1.715,2.401,4.669,2.131,8.07c-0.204,2.581-0.681,6.032-1.379,9.98c-0.955,5.406-3.182,16.546-7.37,30.406
        C195.826,118.671,194.014,119.946,192.051,119.946z"/>
      <path d="M77.209,190.734c-15.262,0-27.678-12.417-27.678-27.679c0-15.261,12.416-27.678,27.678-27.678
        c15.26,0,27.676,12.417,27.676,27.678C104.885,178.317,92.469,190.734,77.209,190.734z M77.209,144.521
        c-10.221,0-18.535,8.314-18.535,18.534s8.314,18.535,18.535,18.535c10.219,0,18.533-8.315,18.533-18.535
        S87.428,144.521,77.209,144.521z"/>
      <path d="M144.83,109.699c-8.9,0-15.207-4.339-21.318-8.543c-0.676-0.466-1.357-0.935-2.049-1.401
        c-2.092-1.414-2.643-4.255-1.228-6.347c1.412-2.092,4.253-2.643,6.347-1.228c0.711,0.48,1.414,0.963,2.112,1.444
        c5.94,4.088,10.645,7.316,17.329,6.895c7.713-0.486,19.059-6.963,26.88-13.167c1.978-1.569,4.853-1.237,6.423,0.741
        c1.568,1.978,1.236,4.854-0.742,6.422c-2.998,2.379-18.635,14.287-31.986,15.128C145.999,109.68,145.408,109.699,144.83,109.699z"/>
    </svg>
  )
}

export default ShareLogo;