import React from 'react';

const HomeLogo = (props) => {
  return (
    <svg {...props} className="custom-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
      <path d="M104.194,211.861c-2.412,0-4.499-1.801-4.798-4.256c-0.321-2.652,1.567-5.064,4.221-5.387l46.448-5.641
        c2.654-0.32,5.064,1.566,5.387,4.221c0.322,2.652-1.567,5.064-4.22,5.387l-46.448,5.641
        C104.585,211.85,104.39,211.861,104.194,211.861z"/>
      <path d="M66.239,197.215c-2.672,0-4.839-2.166-4.839-4.838v-78.701c0-2.672,2.167-4.838,4.839-4.838
        s4.838,2.166,4.838,4.838v78.701C71.077,195.049,68.911,197.215,66.239,197.215z"/>
      <path d="M188.611,197.215c-2.673,0-4.839-2.166-4.839-4.838v-78.701c0-2.672,2.166-4.838,4.839-4.838
        c2.671,0,4.838,2.166,4.838,4.838v78.701C193.449,195.049,191.282,197.215,188.611,197.215z"/>
      <path d="M41.603,140.967c-1.239,0-2.477-0.473-3.421-1.418c-1.89-1.89-1.89-4.953,0-6.842l85.821-85.823
        c1.89-1.889,4.953-1.889,6.842,0c1.892,1.889,1.892,4.953,0,6.843l-85.822,85.822C44.08,140.494,42.841,140.967,41.603,140.967z"/>
      <path d="M213.247,140.967c-1.238,0-2.478-0.473-3.42-1.418l-85.824-85.822c-1.889-1.89-1.889-4.954,0-6.843
        c1.89-1.889,4.953-1.889,6.842,0l85.823,85.823c1.89,1.889,1.89,4.952,0,6.842C215.724,140.494,214.485,140.967,213.247,140.967z"/>
      <path d="M104.2,197.215c-2.673,0-4.84-2.166-4.84-4.838c0-40.221,15.768-61.266,31.343-61.266
        c7.24,0,13.673,4.41,18.112,12.42c4.365,7.872,6.674,19.09,6.674,32.441c0,2.672-2.167,4.838-4.84,4.838s-4.838-2.166-4.838-4.838
        c0-14.799-2.969-23.258-5.459-27.75c-2.658-4.794-6.084-7.434-9.649-7.434c-8.738,0-21.665,16.395-21.665,51.588
        C109.038,195.049,106.873,197.215,104.2,197.215z"/>
    </svg>
  )
}

export default HomeLogo;