import React from 'react';
import Layout from '../layout';

const Privacy = () => {  

  return (
    <Layout>
      <iframe title='Terms of Service' className='privacy-iframe' src="/termsofuse.html"></iframe>
    </Layout>
  )
}

export default Privacy;
